export const MenuItems = 
[
    {
       title : "Home",
       url : "/",
       cName : "nav-links",
    },

    {
        title : "About Us",
        url : "/about",
        cName : "nav-links",
     },

     {
        title : "Tours",
        url : "/service",
        cName : "nav-links",
     },

     {
        title : "Contact",
        url : "/contact",
        cName : "nav-links",
     },

]