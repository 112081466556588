import './service.css'
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import ActionAreaCard from '../component/servicecard';


function Service() {
  return (
    <>  

    <Navbar/>

<ActionAreaCard/>
    


<br /><br /><br />

<Footer/>

   </>
   
  )
}

export default Service
